import {computed, makeObservable, observable, reaction} from 'mobx';

import http from 'http.js';
import autobind from 'common/decorators/autobind.js';
import {ignoreSpecialCharacters} from 'common/regex.js';
import {
    HOSPITAL_CONTACT_TYPE,
    PAGE_TYPE_HOSPITAL,
    PRACTICE_LOC_CONTACT_TYPE,
} from 'core/constants.js';
import PracticeStore from 'practices/stores/PracticeStore.js';

export default class HospitalStore extends PracticeStore {
    displayToolsOnPage = false;
    isHospitalPage = true;
    searchText = '';
    filteredPractitioners = null;
    userType = null;
    mainHeaderSpecialty = null;
    gapSchemeInfo = [];

    constructor(rootStore) {
        super();
        this.rootStore = rootStore;
        makeObservable(this, {
            filteredPractitioners: observable,
            searchText: observable,
            userType: observable,
            mainHeaderSpecialty: observable,
            gapSchemeInfo: observable,
            gapSchemePractitioners: computed,

            hospitalPractitionerIds: computed,
            contactProps: computed,
            groupedPractitioners: computed,
            header: computed,
        });

        reaction(
            () => this.searchText,
            () => this.filterPractitioners(),
        );

        reaction(
            () => this.rootStore.healthFundStore.healthFund,
            (data) => {
                if (data?.id && this.hospitalPractitionerIds?.length > 0) {
                    this.fetchGapSchemeMembership();
                }
            },
        );
    }

    pageType = PAGE_TYPE_HOSPITAL;
    @autobind
    trackPageOpen(e) {
        const hospitalId = parseInt(
            window.sessionStorage.getItem('hospitalId'),
        );

        // it hospital is equal to the practice id (this is hospital id),
        // then do not run the tacking event only call it when get to a new hospital page
        if (this.practice.id !== hospitalId) {
            const healthFundId = this.rootStore.healthFundStore?.healthFund
                ?.id;
            const {device, screenSize, userAgent} = this.deviceInfo;
            const data = {
                'browser_screen_size': screenSize,
                'browser_useragent': userAgent,
                'created_local_time': this.clientLocalTime,
                device,
                'user_type': this.userType,
            };
            data.hospital = this.practice.id;
            if (healthFundId) {
                data['health_fund'] = healthFundId;
            }
            this.rootStore.analyticsStore.trackEvent(
                'hospitalDetailEvent',
                data,
            );
        }
    }

    get hospitalPractitionerIds() {
        return this.practice.practitioners?.map((pr) => pr.id);
    }

    get gapSchemePractitioners() {
        if (!this.groupedPractitioners) {
            return null;
        }
        let isDisplayMainHeader = false;
        const gapSchemePractitionersList = Object.keys(
            this.groupedPractitioners,
        )
            .sort()
            .map((specialty) => {
                const list = {};
                list[specialty] = {gapScheme: [], nonGapScheme: []};
                this.groupedPractitioners[specialty].map((practitioner) => {
                    this.gapSchemeInfo?.forEach((data) => {
                        if (data.id === practitioner.id) {
                            if (data.has_gap_scheme) {
                                list[specialty].gapScheme.push(practitioner);
                                if (!isDisplayMainHeader) {
                                    isDisplayMainHeader = true;
                                    list[
                                        specialty
                                    ].isDisplayMainHeader = isDisplayMainHeader;
                                }
                            } else {
                                list[specialty].nonGapScheme.push(
                                    practitioner,
                                );
                            }
                        }
                    });
                });

                return list;
            });
        return gapSchemePractitionersList;
    }

    get contactProps() {
        const {practice} = this;
        if (practice.phones?.length > 0) {
            return {
                contactId: practice.id,
                contactName: practice.name,
                contactType: HOSPITAL_CONTACT_TYPE,
                items: [
                    {
                        address: practice.address,
                        id: practice.id,
                        name: practice.name,
                        phones: practice.phones,
                        type: HOSPITAL_CONTACT_TYPE,
                    },
                ],
            };
        }
        return null;
    }

    locationListDepartmentContactProps(location, number) {
        return {
            contactName: location.name,
            items: [
                {
                    ...location,
                    type: PRACTICE_LOC_CONTACT_TYPE,
                },
            ],
            selectedItem: {
                id: location.id,
                type: PRACTICE_LOC_CONTACT_TYPE,
            },
            phoneNumber: number,
        };
    }

    get header() {
        const {
            address,
            avatar,
            fax,
            healthFundRelationship,
            group,
            name,
            phones,
            website,
            type,
        } = this.practice;
        return {
            address,
            avatar,
            name,
            healthFundRelationship,
            type,
            group: group?.name,
            phones:
                phones?.length > 0
                    ? {
                          numbers: phones,
                      }
                    : undefined,
            fax: fax && fax,
            website: website
                ? {
                      analyticsEventData: {
                          eventUrl: '/api/hospitals/v1/events/website-opens/',
                          data: {
                              hospital: this.practice.id,
                          },
                      },
                      href: website,
                  }
                : undefined,
        };
    }
    get groupedPractitioners() {
        let groupedPractitioners = {};
        const practitioners =
            this.filteredPractitioners || this.practice.practitioners;
        if (!practitioners.length) {
            return null;
        }
        for (let i = 0; i < practitioners.length; i++) {
            let practitioner = practitioners[i];
            for (let k = 0; k < practitioner.specialties?.length; k++) {
                let key =
                    this.rootStore.paramStore?.isClient ||
                    this.rootStore.paramStore?.isReferrals ||
                    this.rootStore.paramStore?.isProUser
                        ? practitioner.specialties[k].pluralProfessionalName
                        : practitioner.specialties[k].pluralName;
                groupedPractitioners[key] = groupedPractitioners[key] || [];
                groupedPractitioners[key].push(practitioner);
            }
        }
        return groupedPractitioners;
    }

    @autobind
    fetchDefaultTab() {
        let activeTab = null;
        if (
            this.isMobileOrTablet &&
            this.practice.nearbyHospitals?.length > 0
        ) {
            activeTab = this.tabs.find((tab) => tab.hash === 'locations');
        } else if (this.tabs.length) {
            activeTab = this.tabs[0];
        }
        return activeTab;
    }

    @autobind
    async fetchGapSchemeMembership() {
        const healthFund = this.rootStore.healthFundStore.healthFund;
        const gapSchemeInfo = await http.post({
            url: `/api/professionals/v1/gap-scheme-membership/health-fund/${healthFund?.id}`,
            data: {userprofile_ids: this.hospitalPractitionerIds},
        });

        this.updateStore({
            gapSchemeInfo,
        });
    }

    get tabs() {
        const displayTabs = [];
        if (this.practiceLoaded) {
            displayTabs.push(this.overviewTab);
        }
        if (this.isMobileOrTablet && this.practice.nearbyHospitals?.length) {
            const locationsTab = {
                title: 'In-network hospitals',
                hash: 'locations',
                component: 'LocationList',
                count: this.practice.nearbyHospitals?.length,
            };
            displayTabs.push(locationsTab);
        }
        if (this.isMobileOrTablet && this.practice.departments?.length) {
            const departmentsTab = {
                title: 'Departments',
                hash: 'departments',
                component: 'LocationList',
                count: this.practice.departments?.length,
            };
            displayTabs.push(departmentsTab);
        }
        return displayTabs;
    }

    @autobind
    updateSearchText(val) {
        this.updateStore({searchText: val});
    }

    @autobind
    isSearchQueryPresent(field) {
        if (!field) {
            return false;
        }
        const searchQueryRegEx = new RegExp(
            `\\b${ignoreSpecialCharacters(this.searchText.trim()).replace(
                / /g,
                '(" "|.*)\\b',
            )}`,
            'i',
        );
        return field.search(searchQueryRegEx) !== -1;
    }

    filterPractitioners() {
        const filteredPractitioners = this.practice.practitioners.filter(
            (practitioner) =>
                this.isSearchQueryPresent(practitioner.displayName) ||
                (practitioner.specialties?.length > 0 &&
                    this.practitionersForSpecialty(practitioner.specialties)
                        .length > 0),
        );
        this.updateStore({filteredPractitioners});
    }

    @autobind
    practitionersForSpecialty(specialties) {
        return specialties.filter((specialty) => {
            return (
                this.isSearchQueryPresent(specialty.name) ||
                this.isSearchQueryPresent(specialty.professionalName) ||
                this.isSearchQueryPresent(specialty.pluralName) ||
                this.isSearchQueryPresent(specialty.pluralProfessionalName)
            );
        });
    }

    @autobind
    fetchOpenFileData(fileId) {
        if (fileId) {
            return {
                eventUrl: '/api/hospitals/v1/events/file-opens/',
                data: {
                    hospital: this.practice.id,
                    file: fileId,
                },
            };
        } else {
            this.throwError(
                'Missing required file id for tracking hospital file',
            );
        }
    }
}
